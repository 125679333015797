import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Fade
} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {IAppStore} from '../store/appStore.interface';
import Header from "../components/Header";
import {useGlobalStyles} from '../styles/global';
import {useBookingRequestSubscription} from "../api/generated";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      textAlign: 'center',
      '& a': {
        color: '#000',
        textDecoration: 'underline'
      },
    },

    loadingIcon: {
      marginBottom: '2em',
    }
  }),
);

type ConfirmationProps = {
  store: IAppStore,
}

export default function Confirmation({store}: ConfirmationProps ) {
  let history = useHistory();
  const {data, loading, error} = useBookingRequestSubscription({
    variables: {
      id: store.getBookingRequest().id,
    }
  });

  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  const handleNewReservation = () => {
    store.resetReservationDate();
    store.cleanupAfterSuccess();

    history.push('/');
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <>
      <Header store={store} />
      <Container component="main" maxWidth="md">
        <Box className={`${classes.paper} ${classes.body}`}>
          {
            (loading || (data?.bookingRequest_by_pk?.success === null)) && (
              <>
                <Typography variant="h1">
                  Reservierung wird gesendet...
                </Typography>

                <Fade
                  in={loading || !data?.bookingRequest_by_pk?.success}
                  style={{
                    transitionDelay: '0ms',
                  }}
                  unmountOnExit
                >
                  <CircularProgress
                    color='primary'
                    className={classes.loadingIcon}
                    size={'9em'}
                  />
                </Fade>

                <Typography variant="subtitle2">
                  Wir prüfen deine Reservierung.<br />
                  Dies kann einen Moment dauern!
                </Typography>
              </>
            )
          }

          {
            (error || (data?.bookingRequest_by_pk?.success === false)) && (
              <>
              <Typography variant="h1">
                Autsch!
              </Typography>

              <Typography variant="subtitle2" color={"error"}>
                <ErrorOutlineIcon
                  style={{ fontSize: '9em' }}
                  color='error'
                />
                <br />

                Bei Deiner Reservierung gab es ein Problem.<br /><br />
                Wahrscheinlich hat Dir gerade jemand das letzte Bike weggeschnappt.<br/><br/>
                <strong>Bitte versuche es erneut!</strong>
              </Typography>

              <Typography variant="h5" className={classes.body}>
                Fragen?
              </Typography>

              <a target="_blank" rel="noopener noreferrer" href={'https://www.bikepark-brandnertal.at/faq/'}>bikepark-brandnertal.at/faq/</a>
            </>
            )
          }

          {
            data?.bookingRequest_by_pk?.success && (
              <>
                <Typography variant="h1">
                  Reservierung bestätigt!
                </Typography>

                <Typography variant="subtitle2">
                  <CheckCircleIcon
                    style={{ fontSize: '9em' }}
                    color='primary'
                  />
                  <br />

                  Deine Reservierung ist bei uns eingetroffen.<br /><br />
                  Wir haben Dir eine Bestätigung per E-Mail gesendet.<br />
                </Typography>

                <Typography variant="h5" className={classes.body}>
                  Fragen?
                </Typography>

                <a target="_blank" rel="noopener noreferrer" href={'https://www.bikepark-brandnertal.at/faq/'}>bikepark-brandnertal.at/faq/</a>
              </>
            )
          }
        </Box>
      </Container>

      <AppBar position={store.getFooterPosition()} color="default" className={classes.appBar}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleNewReservation}
        >
          Neue Reservierung
        </Button>
      </AppBar>
    </>
  );
};
