import React, { FC, ReactElement } from 'react';
import { useGlobalStyles } from '../styles/global';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Card, CardHeader } from '@material-ui/core';
import { ILocation } from '../store/appStore.interface';

export interface ILocationSelectorProps {
  locations: { id: string; name: string }[];
  activeLocation?: ILocation;
  onSelect?: (location: ILocation) => void;
}

const LocationSelector: FC<ILocationSelectorProps> = ({ locations, onSelect, activeLocation }): ReactElement => {
  const classes = {
    ...useGlobalStyles(),
    ...useGlobalStyles(),
    ...useStyles(),
  };

  return (
    <Box className={classes.locationSelect}>
      {(locations && locations?.length > 1) && locations?.map(location => {
        const isActive = location.id === activeLocation?.id;

        return (
          <Card
            key={location.id}
            // variant={location.id === activeLocation?.id ? "contained" : "outlined"}
            color="primary"
            onClick={() => onSelect && onSelect(location)}
            className={`${classes.locationCard} ${isActive && classes.locationCardActive}`}
          >
            <CardHeader title={location.name} />
            {/*<CardContent>*/}
            {/*  {locationDescriptions.brand}*/}
            {/*</CardContent>*/}
          </Card>
        )
      })}
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    locationSelect: {
      marginTop: '2em',
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      flexDirection: 'column',
      /*cursor: 'pointer',*/
    },
    locationCard: {
      width: '100%',
      marginBottom: '16px'
    },
    locationCardActive: {
      backgroundColor: `${theme.palette.primary.main}22`,
    }
  }),
);

export default LocationSelector;