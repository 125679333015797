import React, { ChangeEvent, useEffect, useState } from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import * as dateFns from "date-fns";

import { ClosingDates, useGetSeasonByLocationQuery } from "../api/generated";
import { IAppStore } from "../store/appStore.interface";
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar
} from "@material-ui/core";
import {useHistory} from 'react-router-dom';
import Header from "../components/Header";
import {useGlobalStyles} from "../styles/global";
import Typography from "@material-ui/core/Typography";
import Footer from "../components/Footer";
import {Alert} from "@material-ui/lab";
import DatePicker from '../components/DatePicker';
import { parseISO } from 'date-fns';
import LoadingContainer from '../components/LoadingContainer';

type DateRangeProps = {
  store: IAppStore,
}

export default function DateRange({store}: DateRangeProps) {
  let history = useHistory();

  const startDateStore = store.getStartDate() ?? null;
  const endDateStore = store.getEndDate() ?? null;
  const durationStore = store.getDuration() ?? 0;

  const initialStartDate = startDateStore ? parseISO(startDateStore as string) : undefined;
  const initialEndDate = endDateStore ? parseISO(endDateStore as string) : undefined;
  const initialDuration = durationStore ? durationStore as number : 0;

  const [currentStartDate, setCurrentStartDate] = useState<Date | undefined>(initialStartDate);
  const [currentEndDate, setCurrentEndDate] = useState<Date | undefined>(initialEndDate);
  const [currentDuration, setCurrentDuration] = useState<number>(initialDuration);
  const [dateConfirmed, setDateConfirmed] = useState<boolean>(false);
  const [isCurrentDateRangeValid, setIsCurrentDateRangeValid] = useState<boolean>(true);

  const [closingDates, setClosingDates] = useState<Array<(
    { __typename?: 'closingDates' }
    & Pick<ClosingDates, 'id' | 'locationId' | 'closedDate' | 'description'>
    )>>();


  if(!store.getLocation()) {
    history.replace('/');
  }

  const classes = {
    ...useGlobalStyles(),
    ...useGlobalStyles(),
    ...useStyles(),
  };

  useEffect(() => {
    if (dateConfirmed) {
      history.push('/products');
    }
  }, [dateConfirmed, history])

  /**
   * GraphQL
   */
  const {data: seasonData, loading: seasonLoading, error: seasonError} = useGetSeasonByLocationQuery({
    variables: { locationId: store.getLocation()?.id },
    skip: store.getLocation()?.id === undefined
  });

  useEffect(() => {
    setClosingDates(seasonData?.closingDates);
  }, [seasonData])

  useEffect(() => {
    setIsCurrentDateRangeValid(true);

    const reservationDays = dateFns.eachDayOfInterval({
      start: currentStartDate ?? dateFns.startOfDay(new Date()),
      end: currentEndDate ?? dateFns.startOfDay(new Date()),
    });

    reservationDays.map((date) => {
      closingDates?.map(({ closedDate: value }) => {
        const closedDate = parseISO(value);

        if(dateFns.isSameDay(closedDate, date)) {
          setIsCurrentDateRangeValid(false);
        }

        return null;
      })

      return null;
    })
  }, [currentStartDate, currentEndDate, closingDates]);

  if (seasonError) return <p>Error :(</p>;

  /**
   * Handlers
   */


  const handleConfirmDate = async () => {
    if (currentStartDate && currentDuration) {
      store.setStartDate(dateFns.format(currentStartDate, 'yyyy-MM-dd'));
      store.setDuration(currentDuration);
      store.confirmReservationDate();
      setDateConfirmed(true);
    }
  }

  const handleDateChange = async (date: Date): Promise<void> => {
    const duration = currentDuration < 2 ? 0 : currentDuration - 1;

    setCurrentStartDate(date);
    setCurrentEndDate(dateFns.addDays(date, duration));
  };

  const handleDurationChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    const value = event.target.value as number;

    if (currentStartDate) {
      setCurrentDuration(value);
      setCurrentEndDate(dateFns.addDays(currentStartDate, value < 2 ? 0 : value - 1));
    }
  }

  return (
    <>
      <Header store={store} />
      <Container component="main" maxWidth="md" className={classes.root}>
        <Box className={classes.paper}>
          {seasonLoading ? (
            <LoadingContainer />
          ) : (
            <>
              <Typography variant="h1">
                Datum & Dauer
              </Typography>

              <Box className={classes.datePickerContainer}>
                <DatePicker
                  locationId={store.getLocation()?.id ?? ''}
                  onSelect={handleDateChange}
                  startDate={currentStartDate}
                  endDate={currentEndDate}
                />
              </Box>

              {(currentStartDate) && (
                <>
                  <Typography variant={'h5'} className={classes.durationHeader}>
                    Wie lange möchtest Du reservieren?
                  </Typography>

                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Dauer</InputLabel>
                    <Select
                      value={currentDuration?.toString(10)}
                      onChange={handleDurationChange}
                      label="Dauer"
                      fullWidth
                      className={classes.durationSelect}
                    >
                      <MenuItem value={0.5}>4 Stunden</MenuItem>
                      <MenuItem value={1}>1 Tag</MenuItem>
                      <MenuItem value={2}>2 Tage</MenuItem>
                      <MenuItem value={3}>3 Tage</MenuItem>
                      <MenuItem value={4}>4 Tage</MenuItem>
                      <MenuItem value={5}>5 Tage</MenuItem>
                      <MenuItem value={6}>6 Tage</MenuItem>
                      <MenuItem value={7}>7 Tage</MenuItem>
                    </Select>
                  </FormControl>
                </>
              )}
            </>
          )}
        </Box>



        {/* Todo: Display toggled by DatePicker status / callback */}
        <>
          <Snackbar open={!isCurrentDateRangeValid} autoHideDuration={6000}>
            <Alert
              severity="error"
            >
              Der Zeitraum dieser Reservierung ist ungültig.<br />
            </Alert>
          </Snackbar>
        </>
      </Container>

      {
        (currentDuration ?? 0) > 0
        && (
          <Footer store={store}>
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleConfirmDate()}
                className={classes.primaryButton}
              >
                Produkte wählen
              </Button>
            </>
          </Footer>
        )
      }
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiPickersStaticWrapper-staticWrapperRoot': {
        minWidth: 'auto'
      },
      '& .MuiPickersBasePicker-pickerView': {
        minWidth: 'auto',
        maxWidth: 'auto',
      },
    },
    wrapperHighlightedDay: {},
    highlightedDay: {
      '& .MuiPickersDay-day': {
        backgroundColor: theme.palette.primary.main,
      },
      '& .MuiPickersDay-dayDisabled': {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.info.contrastText
      }
    },
    durationSelect: {
      padding: 0,
      '& .MuiInputBase-input': {
        padding: '0.9em'
      }
    },
    durationHeader: {
      marginTop: '6px',
    },
    datePickerContainer: {
      minHeight: '305px',
    }
  }),
);