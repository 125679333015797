import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  uuid: any;
  date: any;
  numeric: any;
  bigint: any;
  timestamptz: any;
  jsonb: any;
};


export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

export type BookingRequest = {
   __typename?: 'bookingRequest';
  id: Scalars['uuid'];
  success?: Maybe<Scalars['Boolean']>;
};

export type BookingRequest_Arr_Rel_Insert_Input = {
  data: Array<BookingRequest_Insert_Input>;
};

export type BookingRequest_Bool_Exp = {
  _and?: Maybe<Array<Maybe<BookingRequest_Bool_Exp>>>;
  _not?: Maybe<BookingRequest_Bool_Exp>;
  _or?: Maybe<Array<Maybe<BookingRequest_Bool_Exp>>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  success?: Maybe<Boolean_Comparison_Exp>;
};

export type BookingRequest_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  request?: Maybe<Scalars['jsonb']>;
};

export type BookingRequest_Mutation_Response = {
   __typename?: 'bookingRequest_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<BookingRequest>;
};

export type BookingRequest_Obj_Rel_Insert_Input = {
  data: BookingRequest_Insert_Input;
};

export type BookingRequest_Order_By = {
  id?: Maybe<Order_By>;
  success?: Maybe<Order_By>;
};

export type BookingRequest_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

export enum BookingRequest_Select_Column {
  Id = 'id',
  Success = 'success'
}

export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type ClosingDates = {
   __typename?: 'closingDates';
  closedDate: Scalars['date'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  location: Locations;
  locationId: Scalars['uuid'];
};

export type ClosingDates_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ClosingDates_Bool_Exp>>>;
  _not?: Maybe<ClosingDates_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ClosingDates_Bool_Exp>>>;
  closedDate?: Maybe<Date_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
};

export type ClosingDates_Order_By = {
  closedDate?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  locationId?: Maybe<Order_By>;
};

export type ClosingDates_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

export enum ClosingDates_Select_Column {
  ClosedDate = 'closedDate',
  Description = 'description',
  Id = 'id',
  LocationId = 'locationId'
}


export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};


export type Jsonb_Comparison_Exp = {
  _contained_in?: Maybe<Scalars['jsonb']>;
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  _has_key?: Maybe<Scalars['String']>;
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

export type Locations = {
   __typename?: 'locations';
  closingDates: Array<ClosingDates>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  products: Array<Products>;
  products_aggregate: Products_Aggregate;
  seasons: Array<Seasons>;
};


export type LocationsClosingDatesArgs = {
  distinct_on?: Maybe<Array<ClosingDates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClosingDates_Order_By>>;
  where?: Maybe<ClosingDates_Bool_Exp>;
};


export type LocationsProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type LocationsProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type LocationsSeasonsArgs = {
  distinct_on?: Maybe<Array<Seasons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Seasons_Order_By>>;
  where?: Maybe<Seasons_Bool_Exp>;
};

export type Locations_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Locations_Bool_Exp>>>;
  _not?: Maybe<Locations_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Locations_Bool_Exp>>>;
  closingDates?: Maybe<ClosingDates_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  products?: Maybe<Products_Bool_Exp>;
  seasons?: Maybe<Seasons_Bool_Exp>;
};

export type Locations_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  products_aggregate?: Maybe<Products_Aggregate_Order_By>;
};

export type Locations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

export enum Locations_Select_Column {
  Id = 'id',
  Name = 'name'
}

export type Mutation_Root = {
   __typename?: 'mutation_root';
  insert_bookingRequest?: Maybe<BookingRequest_Mutation_Response>;
  insert_bookingRequest_one?: Maybe<BookingRequest>;
};


export type Mutation_RootInsert_BookingRequestArgs = {
  objects: Array<BookingRequest_Insert_Input>;
};


export type Mutation_RootInsert_BookingRequest_OneArgs = {
  object: BookingRequest_Insert_Input;
};


export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

export enum Order_By {
  Asc = 'asc',
  AscNullsFirst = 'asc_nulls_first',
  AscNullsLast = 'asc_nulls_last',
  Desc = 'desc',
  DescNullsFirst = 'desc_nulls_first',
  DescNullsLast = 'desc_nulls_last'
}

export type ProductPrices = {
   __typename?: 'productPrices';
  basePricePerDay: Scalars['Int'];
  id: Scalars['uuid'];
  insuranceCostsPerDay: Scalars['Int'];
  minDuration: Scalars['numeric'];
  product: Products;
  productId: Scalars['uuid'];
  protectionCostsPerDay: Scalars['Int'];
};

export type ProductPrices_Aggregate = {
   __typename?: 'productPrices_aggregate';
  aggregate?: Maybe<ProductPrices_Aggregate_Fields>;
  nodes: Array<ProductPrices>;
};

export type ProductPrices_Aggregate_Fields = {
   __typename?: 'productPrices_aggregate_fields';
  avg?: Maybe<ProductPrices_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ProductPrices_Max_Fields>;
  min?: Maybe<ProductPrices_Min_Fields>;
  stddev?: Maybe<ProductPrices_Stddev_Fields>;
  stddev_pop?: Maybe<ProductPrices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ProductPrices_Stddev_Samp_Fields>;
  sum?: Maybe<ProductPrices_Sum_Fields>;
  var_pop?: Maybe<ProductPrices_Var_Pop_Fields>;
  var_samp?: Maybe<ProductPrices_Var_Samp_Fields>;
  variance?: Maybe<ProductPrices_Variance_Fields>;
};


export type ProductPrices_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ProductPrices_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type ProductPrices_Aggregate_Order_By = {
  avg?: Maybe<ProductPrices_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ProductPrices_Max_Order_By>;
  min?: Maybe<ProductPrices_Min_Order_By>;
  stddev?: Maybe<ProductPrices_Stddev_Order_By>;
  stddev_pop?: Maybe<ProductPrices_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ProductPrices_Stddev_Samp_Order_By>;
  sum?: Maybe<ProductPrices_Sum_Order_By>;
  var_pop?: Maybe<ProductPrices_Var_Pop_Order_By>;
  var_samp?: Maybe<ProductPrices_Var_Samp_Order_By>;
  variance?: Maybe<ProductPrices_Variance_Order_By>;
};

export type ProductPrices_Avg_Fields = {
   __typename?: 'productPrices_avg_fields';
  basePricePerDay?: Maybe<Scalars['Float']>;
  insuranceCostsPerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  protectionCostsPerDay?: Maybe<Scalars['Float']>;
};

export type ProductPrices_Avg_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

export type ProductPrices_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ProductPrices_Bool_Exp>>>;
  _not?: Maybe<ProductPrices_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ProductPrices_Bool_Exp>>>;
  basePricePerDay?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  insuranceCostsPerDay?: Maybe<Int_Comparison_Exp>;
  minDuration?: Maybe<Numeric_Comparison_Exp>;
  product?: Maybe<Products_Bool_Exp>;
  productId?: Maybe<Uuid_Comparison_Exp>;
  protectionCostsPerDay?: Maybe<Int_Comparison_Exp>;
};

export type ProductPrices_Max_Fields = {
   __typename?: 'productPrices_max_fields';
  basePricePerDay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  insuranceCostsPerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
  productId?: Maybe<Scalars['uuid']>;
  protectionCostsPerDay?: Maybe<Scalars['Int']>;
};

export type ProductPrices_Max_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  productId?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

export type ProductPrices_Min_Fields = {
   __typename?: 'productPrices_min_fields';
  basePricePerDay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  insuranceCostsPerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
  productId?: Maybe<Scalars['uuid']>;
  protectionCostsPerDay?: Maybe<Scalars['Int']>;
};

export type ProductPrices_Min_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  productId?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

export type ProductPrices_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  product?: Maybe<Products_Order_By>;
  productId?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

export type ProductPrices_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

export enum ProductPrices_Select_Column {
  BasePricePerDay = 'basePricePerDay',
  Id = 'id',
  InsuranceCostsPerDay = 'insuranceCostsPerDay',
  MinDuration = 'minDuration',
  ProductId = 'productId',
  ProtectionCostsPerDay = 'protectionCostsPerDay'
}

export type ProductPrices_Stddev_Fields = {
   __typename?: 'productPrices_stddev_fields';
  basePricePerDay?: Maybe<Scalars['Float']>;
  insuranceCostsPerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  protectionCostsPerDay?: Maybe<Scalars['Float']>;
};

export type ProductPrices_Stddev_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

export type ProductPrices_Stddev_Pop_Fields = {
   __typename?: 'productPrices_stddev_pop_fields';
  basePricePerDay?: Maybe<Scalars['Float']>;
  insuranceCostsPerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  protectionCostsPerDay?: Maybe<Scalars['Float']>;
};

export type ProductPrices_Stddev_Pop_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

export type ProductPrices_Stddev_Samp_Fields = {
   __typename?: 'productPrices_stddev_samp_fields';
  basePricePerDay?: Maybe<Scalars['Float']>;
  insuranceCostsPerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  protectionCostsPerDay?: Maybe<Scalars['Float']>;
};

export type ProductPrices_Stddev_Samp_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

export type ProductPrices_Sum_Fields = {
   __typename?: 'productPrices_sum_fields';
  basePricePerDay?: Maybe<Scalars['Int']>;
  insuranceCostsPerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
  protectionCostsPerDay?: Maybe<Scalars['Int']>;
};

export type ProductPrices_Sum_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

export type ProductPrices_Var_Pop_Fields = {
   __typename?: 'productPrices_var_pop_fields';
  basePricePerDay?: Maybe<Scalars['Float']>;
  insuranceCostsPerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  protectionCostsPerDay?: Maybe<Scalars['Float']>;
};

export type ProductPrices_Var_Pop_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

export type ProductPrices_Var_Samp_Fields = {
   __typename?: 'productPrices_var_samp_fields';
  basePricePerDay?: Maybe<Scalars['Float']>;
  insuranceCostsPerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  protectionCostsPerDay?: Maybe<Scalars['Float']>;
};

export type ProductPrices_Var_Samp_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

export type ProductPrices_Variance_Fields = {
   __typename?: 'productPrices_variance_fields';
  basePricePerDay?: Maybe<Scalars['Float']>;
  insuranceCostsPerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  protectionCostsPerDay?: Maybe<Scalars['Float']>;
};

export type ProductPrices_Variance_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

export type Products = {
   __typename?: 'products';
  id: Scalars['uuid'];
  location: Locations;
  productPrices: Array<ProductPrices>;
  productPrices_aggregate: ProductPrices_Aggregate;
  productType: ProductTypes;
  productTypeId: Scalars['uuid'];
  size: Scalars['String'];
  snapshotAvailabilities: Array<SnapshotAvailabilities>;
  snapshotAvailabilities_aggregate: SnapshotAvailabilities_Aggregate;
};


export type ProductsProductPricesArgs = {
  distinct_on?: Maybe<Array<ProductPrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductPrices_Order_By>>;
  where?: Maybe<ProductPrices_Bool_Exp>;
};


export type ProductsProductPrices_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductPrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductPrices_Order_By>>;
  where?: Maybe<ProductPrices_Bool_Exp>;
};


export type ProductsSnapshotAvailabilitiesArgs = {
  distinct_on?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotAvailabilities_Order_By>>;
  where?: Maybe<SnapshotAvailabilities_Bool_Exp>;
};


export type ProductsSnapshotAvailabilities_AggregateArgs = {
  distinct_on?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotAvailabilities_Order_By>>;
  where?: Maybe<SnapshotAvailabilities_Bool_Exp>;
};

export type Products_Aggregate = {
   __typename?: 'products_aggregate';
  aggregate?: Maybe<Products_Aggregate_Fields>;
  nodes: Array<Products>;
};

export type Products_Aggregate_Fields = {
   __typename?: 'products_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Products_Max_Fields>;
  min?: Maybe<Products_Min_Fields>;
};


export type Products_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Products_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type Products_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Products_Max_Order_By>;
  min?: Maybe<Products_Min_Order_By>;
};

export type Products_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Products_Bool_Exp>>>;
  _not?: Maybe<Products_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Products_Bool_Exp>>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  productPrices?: Maybe<ProductPrices_Bool_Exp>;
  productType?: Maybe<ProductTypes_Bool_Exp>;
  productTypeId?: Maybe<Uuid_Comparison_Exp>;
  size?: Maybe<String_Comparison_Exp>;
  snapshotAvailabilities?: Maybe<SnapshotAvailabilities_Bool_Exp>;
};

export type Products_Max_Fields = {
   __typename?: 'products_max_fields';
  id?: Maybe<Scalars['uuid']>;
  productTypeId?: Maybe<Scalars['uuid']>;
  size?: Maybe<Scalars['String']>;
};

export type Products_Max_Order_By = {
  id?: Maybe<Order_By>;
  productTypeId?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
};

export type Products_Min_Fields = {
   __typename?: 'products_min_fields';
  id?: Maybe<Scalars['uuid']>;
  productTypeId?: Maybe<Scalars['uuid']>;
  size?: Maybe<Scalars['String']>;
};

export type Products_Min_Order_By = {
  id?: Maybe<Order_By>;
  productTypeId?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
};

export type Products_Order_By = {
  id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  productPrices_aggregate?: Maybe<ProductPrices_Aggregate_Order_By>;
  productType?: Maybe<ProductTypes_Order_By>;
  productTypeId?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  snapshotAvailabilities_aggregate?: Maybe<SnapshotAvailabilities_Aggregate_Order_By>;
};

export type Products_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

export enum Products_Select_Column {
  Id = 'id',
  ProductTypeId = 'productTypeId',
  Size = 'size'
}

export type ProductTypeAvailabilities = {
   __typename?: 'productTypeAvailabilities';
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  products: Array<Products>;
  products_aggregate: Products_Aggregate;
  sumAvailableBikes?: Maybe<Scalars['bigint']>;
};


export type ProductTypeAvailabilitiesProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type ProductTypeAvailabilitiesProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};

export type ProductTypeAvailabilities_Aggregate = {
   __typename?: 'productTypeAvailabilities_aggregate';
  aggregate?: Maybe<ProductTypeAvailabilities_Aggregate_Fields>;
  nodes: Array<ProductTypeAvailabilities>;
};

export type ProductTypeAvailabilities_Aggregate_Fields = {
   __typename?: 'productTypeAvailabilities_aggregate_fields';
  avg?: Maybe<ProductTypeAvailabilities_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ProductTypeAvailabilities_Max_Fields>;
  min?: Maybe<ProductTypeAvailabilities_Min_Fields>;
  stddev?: Maybe<ProductTypeAvailabilities_Stddev_Fields>;
  stddev_pop?: Maybe<ProductTypeAvailabilities_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ProductTypeAvailabilities_Stddev_Samp_Fields>;
  sum?: Maybe<ProductTypeAvailabilities_Sum_Fields>;
  var_pop?: Maybe<ProductTypeAvailabilities_Var_Pop_Fields>;
  var_samp?: Maybe<ProductTypeAvailabilities_Var_Samp_Fields>;
  variance?: Maybe<ProductTypeAvailabilities_Variance_Fields>;
};


export type ProductTypeAvailabilities_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type ProductTypeAvailabilities_Aggregate_Order_By = {
  avg?: Maybe<ProductTypeAvailabilities_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ProductTypeAvailabilities_Max_Order_By>;
  min?: Maybe<ProductTypeAvailabilities_Min_Order_By>;
  stddev?: Maybe<ProductTypeAvailabilities_Stddev_Order_By>;
  stddev_pop?: Maybe<ProductTypeAvailabilities_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ProductTypeAvailabilities_Stddev_Samp_Order_By>;
  sum?: Maybe<ProductTypeAvailabilities_Sum_Order_By>;
  var_pop?: Maybe<ProductTypeAvailabilities_Var_Pop_Order_By>;
  var_samp?: Maybe<ProductTypeAvailabilities_Var_Samp_Order_By>;
  variance?: Maybe<ProductTypeAvailabilities_Variance_Order_By>;
};

export type ProductTypeAvailabilities_Avg_Fields = {
   __typename?: 'productTypeAvailabilities_avg_fields';
  sumAvailableBikes?: Maybe<Scalars['Float']>;
};

export type ProductTypeAvailabilities_Avg_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

export type ProductTypeAvailabilities_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ProductTypeAvailabilities_Bool_Exp>>>;
  _not?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ProductTypeAvailabilities_Bool_Exp>>>;
  date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  products?: Maybe<Products_Bool_Exp>;
  sumAvailableBikes?: Maybe<Bigint_Comparison_Exp>;
};

export type ProductTypeAvailabilities_Max_Fields = {
   __typename?: 'productTypeAvailabilities_max_fields';
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  sumAvailableBikes?: Maybe<Scalars['bigint']>;
};

export type ProductTypeAvailabilities_Max_Order_By = {
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  sumAvailableBikes?: Maybe<Order_By>;
};

export type ProductTypeAvailabilities_Min_Fields = {
   __typename?: 'productTypeAvailabilities_min_fields';
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  sumAvailableBikes?: Maybe<Scalars['bigint']>;
};

export type ProductTypeAvailabilities_Min_Order_By = {
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  sumAvailableBikes?: Maybe<Order_By>;
};

export type ProductTypeAvailabilities_Order_By = {
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  products_aggregate?: Maybe<Products_Aggregate_Order_By>;
  sumAvailableBikes?: Maybe<Order_By>;
};

export enum ProductTypeAvailabilities_Select_Column {
  Date = 'date',
  Id = 'id',
  LocationId = 'locationId',
  SumAvailableBikes = 'sumAvailableBikes'
}

export type ProductTypeAvailabilities_Stddev_Fields = {
   __typename?: 'productTypeAvailabilities_stddev_fields';
  sumAvailableBikes?: Maybe<Scalars['Float']>;
};

export type ProductTypeAvailabilities_Stddev_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

export type ProductTypeAvailabilities_Stddev_Pop_Fields = {
   __typename?: 'productTypeAvailabilities_stddev_pop_fields';
  sumAvailableBikes?: Maybe<Scalars['Float']>;
};

export type ProductTypeAvailabilities_Stddev_Pop_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

export type ProductTypeAvailabilities_Stddev_Samp_Fields = {
   __typename?: 'productTypeAvailabilities_stddev_samp_fields';
  sumAvailableBikes?: Maybe<Scalars['Float']>;
};

export type ProductTypeAvailabilities_Stddev_Samp_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

export type ProductTypeAvailabilities_Sum_Fields = {
   __typename?: 'productTypeAvailabilities_sum_fields';
  sumAvailableBikes?: Maybe<Scalars['bigint']>;
};

export type ProductTypeAvailabilities_Sum_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

export type ProductTypeAvailabilities_Var_Pop_Fields = {
   __typename?: 'productTypeAvailabilities_var_pop_fields';
  sumAvailableBikes?: Maybe<Scalars['Float']>;
};

export type ProductTypeAvailabilities_Var_Pop_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

export type ProductTypeAvailabilities_Var_Samp_Fields = {
   __typename?: 'productTypeAvailabilities_var_samp_fields';
  sumAvailableBikes?: Maybe<Scalars['Float']>;
};

export type ProductTypeAvailabilities_Var_Samp_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

export type ProductTypeAvailabilities_Variance_Fields = {
   __typename?: 'productTypeAvailabilities_variance_fields';
  sumAvailableBikes?: Maybe<Scalars['Float']>;
};

export type ProductTypeAvailabilities_Variance_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

export type ProductTypeModels = {
   __typename?: 'productTypeModels';
  id: Scalars['String'];
  name: Scalars['String'];
  productTypes: Array<ProductTypes>;
  productTypes_aggregate: ProductTypes_Aggregate;
};


export type ProductTypeModelsProductTypesArgs = {
  distinct_on?: Maybe<Array<ProductTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypes_Order_By>>;
  where?: Maybe<ProductTypes_Bool_Exp>;
};


export type ProductTypeModelsProductTypes_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypes_Order_By>>;
  where?: Maybe<ProductTypes_Bool_Exp>;
};

export type ProductTypeModels_Aggregate = {
   __typename?: 'productTypeModels_aggregate';
  aggregate?: Maybe<ProductTypeModels_Aggregate_Fields>;
  nodes: Array<ProductTypeModels>;
};

export type ProductTypeModels_Aggregate_Fields = {
   __typename?: 'productTypeModels_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ProductTypeModels_Max_Fields>;
  min?: Maybe<ProductTypeModels_Min_Fields>;
};


export type ProductTypeModels_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ProductTypeModels_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type ProductTypeModels_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ProductTypeModels_Max_Order_By>;
  min?: Maybe<ProductTypeModels_Min_Order_By>;
};

export type ProductTypeModels_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ProductTypeModels_Bool_Exp>>>;
  _not?: Maybe<ProductTypeModels_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ProductTypeModels_Bool_Exp>>>;
  id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  productTypes?: Maybe<ProductTypes_Bool_Exp>;
};

export type ProductTypeModels_Max_Fields = {
   __typename?: 'productTypeModels_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ProductTypeModels_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

export type ProductTypeModels_Min_Fields = {
   __typename?: 'productTypeModels_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ProductTypeModels_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

export type ProductTypeModels_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  productTypes_aggregate?: Maybe<ProductTypes_Aggregate_Order_By>;
};

export type ProductTypeModels_Pk_Columns_Input = {
  id: Scalars['String'];
};

export enum ProductTypeModels_Select_Column {
  Id = 'id',
  Name = 'name'
}

export type ProductTypePrices = {
   __typename?: 'productTypePrices';
  id?: Maybe<Scalars['uuid']>;
  minBasePricePerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
};

export type ProductTypePrices_Aggregate = {
   __typename?: 'productTypePrices_aggregate';
  aggregate?: Maybe<ProductTypePrices_Aggregate_Fields>;
  nodes: Array<ProductTypePrices>;
};

export type ProductTypePrices_Aggregate_Fields = {
   __typename?: 'productTypePrices_aggregate_fields';
  avg?: Maybe<ProductTypePrices_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ProductTypePrices_Max_Fields>;
  min?: Maybe<ProductTypePrices_Min_Fields>;
  stddev?: Maybe<ProductTypePrices_Stddev_Fields>;
  stddev_pop?: Maybe<ProductTypePrices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ProductTypePrices_Stddev_Samp_Fields>;
  sum?: Maybe<ProductTypePrices_Sum_Fields>;
  var_pop?: Maybe<ProductTypePrices_Var_Pop_Fields>;
  var_samp?: Maybe<ProductTypePrices_Var_Samp_Fields>;
  variance?: Maybe<ProductTypePrices_Variance_Fields>;
};


export type ProductTypePrices_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ProductTypePrices_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type ProductTypePrices_Aggregate_Order_By = {
  avg?: Maybe<ProductTypePrices_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ProductTypePrices_Max_Order_By>;
  min?: Maybe<ProductTypePrices_Min_Order_By>;
  stddev?: Maybe<ProductTypePrices_Stddev_Order_By>;
  stddev_pop?: Maybe<ProductTypePrices_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ProductTypePrices_Stddev_Samp_Order_By>;
  sum?: Maybe<ProductTypePrices_Sum_Order_By>;
  var_pop?: Maybe<ProductTypePrices_Var_Pop_Order_By>;
  var_samp?: Maybe<ProductTypePrices_Var_Samp_Order_By>;
  variance?: Maybe<ProductTypePrices_Variance_Order_By>;
};

export type ProductTypePrices_Avg_Fields = {
   __typename?: 'productTypePrices_avg_fields';
  minBasePricePerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
};

export type ProductTypePrices_Avg_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

export type ProductTypePrices_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ProductTypePrices_Bool_Exp>>>;
  _not?: Maybe<ProductTypePrices_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ProductTypePrices_Bool_Exp>>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  minBasePricePerDay?: Maybe<Int_Comparison_Exp>;
  minDuration?: Maybe<Numeric_Comparison_Exp>;
};

export type ProductTypePrices_Max_Fields = {
   __typename?: 'productTypePrices_max_fields';
  id?: Maybe<Scalars['uuid']>;
  minBasePricePerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
};

export type ProductTypePrices_Max_Order_By = {
  id?: Maybe<Order_By>;
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

export type ProductTypePrices_Min_Fields = {
   __typename?: 'productTypePrices_min_fields';
  id?: Maybe<Scalars['uuid']>;
  minBasePricePerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
};

export type ProductTypePrices_Min_Order_By = {
  id?: Maybe<Order_By>;
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

export type ProductTypePrices_Order_By = {
  id?: Maybe<Order_By>;
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

export enum ProductTypePrices_Select_Column {
  Id = 'id',
  MinBasePricePerDay = 'minBasePricePerDay',
  MinDuration = 'minDuration'
}

export type ProductTypePrices_Stddev_Fields = {
   __typename?: 'productTypePrices_stddev_fields';
  minBasePricePerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
};

export type ProductTypePrices_Stddev_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

export type ProductTypePrices_Stddev_Pop_Fields = {
   __typename?: 'productTypePrices_stddev_pop_fields';
  minBasePricePerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
};

export type ProductTypePrices_Stddev_Pop_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

export type ProductTypePrices_Stddev_Samp_Fields = {
   __typename?: 'productTypePrices_stddev_samp_fields';
  minBasePricePerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
};

export type ProductTypePrices_Stddev_Samp_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

export type ProductTypePrices_Sum_Fields = {
   __typename?: 'productTypePrices_sum_fields';
  minBasePricePerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
};

export type ProductTypePrices_Sum_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

export type ProductTypePrices_Var_Pop_Fields = {
   __typename?: 'productTypePrices_var_pop_fields';
  minBasePricePerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
};

export type ProductTypePrices_Var_Pop_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

export type ProductTypePrices_Var_Samp_Fields = {
   __typename?: 'productTypePrices_var_samp_fields';
  minBasePricePerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
};

export type ProductTypePrices_Var_Samp_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

export type ProductTypePrices_Variance_Fields = {
   __typename?: 'productTypePrices_variance_fields';
  minBasePricePerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
};

export type ProductTypePrices_Variance_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

export type ProductTypes = {
   __typename?: 'productTypes';
  availabilities: Array<ProductTypeAvailabilities>;
  availabilities_aggregate: ProductTypeAvailabilities_Aggregate;
  category: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  image: Scalars['String'];
  name: Scalars['String'];
  prices: Array<ProductTypePrices>;
  prices_aggregate: ProductTypePrices_Aggregate;
  productTypeModel: ProductTypeModels;
  productTypeModelId: Scalars['String'];
  products: Array<Products>;
  products_aggregate: Products_Aggregate;
};


export type ProductTypesAvailabilitiesArgs = {
  distinct_on?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeAvailabilities_Order_By>>;
  where?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
};


export type ProductTypesAvailabilities_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeAvailabilities_Order_By>>;
  where?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
};


export type ProductTypesPricesArgs = {
  distinct_on?: Maybe<Array<ProductTypePrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypePrices_Order_By>>;
  where?: Maybe<ProductTypePrices_Bool_Exp>;
};


export type ProductTypesPrices_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypePrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypePrices_Order_By>>;
  where?: Maybe<ProductTypePrices_Bool_Exp>;
};


export type ProductTypesProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type ProductTypesProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};

export type ProductTypes_Aggregate = {
   __typename?: 'productTypes_aggregate';
  aggregate?: Maybe<ProductTypes_Aggregate_Fields>;
  nodes: Array<ProductTypes>;
};

export type ProductTypes_Aggregate_Fields = {
   __typename?: 'productTypes_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ProductTypes_Max_Fields>;
  min?: Maybe<ProductTypes_Min_Fields>;
};


export type ProductTypes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ProductTypes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type ProductTypes_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ProductTypes_Max_Order_By>;
  min?: Maybe<ProductTypes_Min_Order_By>;
};

export type ProductTypes_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ProductTypes_Bool_Exp>>>;
  _not?: Maybe<ProductTypes_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ProductTypes_Bool_Exp>>>;
  availabilities?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
  category?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  image?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  prices?: Maybe<ProductTypePrices_Bool_Exp>;
  productTypeModel?: Maybe<ProductTypeModels_Bool_Exp>;
  productTypeModelId?: Maybe<String_Comparison_Exp>;
  products?: Maybe<Products_Bool_Exp>;
};

export type ProductTypes_Max_Fields = {
   __typename?: 'productTypes_max_fields';
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productTypeModelId?: Maybe<Scalars['String']>;
};

export type ProductTypes_Max_Order_By = {
  category?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  productTypeModelId?: Maybe<Order_By>;
};

export type ProductTypes_Min_Fields = {
   __typename?: 'productTypes_min_fields';
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productTypeModelId?: Maybe<Scalars['String']>;
};

export type ProductTypes_Min_Order_By = {
  category?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  productTypeModelId?: Maybe<Order_By>;
};

export type ProductTypes_Order_By = {
  availabilities_aggregate?: Maybe<ProductTypeAvailabilities_Aggregate_Order_By>;
  category?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  prices_aggregate?: Maybe<ProductTypePrices_Aggregate_Order_By>;
  productTypeModel?: Maybe<ProductTypeModels_Order_By>;
  productTypeModelId?: Maybe<Order_By>;
  products_aggregate?: Maybe<Products_Aggregate_Order_By>;
};

export type ProductTypes_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

export enum ProductTypes_Select_Column {
  Category = 'category',
  Description = 'description',
  Id = 'id',
  Image = 'image',
  Name = 'name',
  ProductTypeModelId = 'productTypeModelId'
}

export type Query_Root = {
   __typename?: 'query_root';
  bookingRequest: Array<BookingRequest>;
  bookingRequest_by_pk?: Maybe<BookingRequest>;
  closingDates: Array<ClosingDates>;
  closingDates_by_pk?: Maybe<ClosingDates>;
  locations: Array<Locations>;
  locations_by_pk?: Maybe<Locations>;
  productPrices: Array<ProductPrices>;
  productPrices_aggregate: ProductPrices_Aggregate;
  productPrices_by_pk?: Maybe<ProductPrices>;
  productTypeAvailabilities: Array<ProductTypeAvailabilities>;
  productTypeAvailabilities_aggregate: ProductTypeAvailabilities_Aggregate;
  productTypeModels: Array<ProductTypeModels>;
  productTypeModels_aggregate: ProductTypeModels_Aggregate;
  productTypeModels_by_pk?: Maybe<ProductTypeModels>;
  productTypePrices: Array<ProductTypePrices>;
  productTypePrices_aggregate: ProductTypePrices_Aggregate;
  productTypes: Array<ProductTypes>;
  productTypes_aggregate: ProductTypes_Aggregate;
  productTypes_by_pk?: Maybe<ProductTypes>;
  products: Array<Products>;
  products_aggregate: Products_Aggregate;
  products_by_pk?: Maybe<Products>;
  seasons: Array<Seasons>;
  seasons_by_pk?: Maybe<Seasons>;
  snapshotAvailabilities: Array<SnapshotAvailabilities>;
  snapshotAvailabilities_aggregate: SnapshotAvailabilities_Aggregate;
  snapshotAvailabilities_by_pk?: Maybe<SnapshotAvailabilities>;
};


export type Query_RootBookingRequestArgs = {
  distinct_on?: Maybe<Array<BookingRequest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BookingRequest_Order_By>>;
  where?: Maybe<BookingRequest_Bool_Exp>;
};


export type Query_RootBookingRequest_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootClosingDatesArgs = {
  distinct_on?: Maybe<Array<ClosingDates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClosingDates_Order_By>>;
  where?: Maybe<ClosingDates_Bool_Exp>;
};


export type Query_RootClosingDates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLocationsArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Query_RootLocations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProductPricesArgs = {
  distinct_on?: Maybe<Array<ProductPrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductPrices_Order_By>>;
  where?: Maybe<ProductPrices_Bool_Exp>;
};


export type Query_RootProductPrices_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductPrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductPrices_Order_By>>;
  where?: Maybe<ProductPrices_Bool_Exp>;
};


export type Query_RootProductPrices_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProductTypeAvailabilitiesArgs = {
  distinct_on?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeAvailabilities_Order_By>>;
  where?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
};


export type Query_RootProductTypeAvailabilities_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeAvailabilities_Order_By>>;
  where?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
};


export type Query_RootProductTypeModelsArgs = {
  distinct_on?: Maybe<Array<ProductTypeModels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeModels_Order_By>>;
  where?: Maybe<ProductTypeModels_Bool_Exp>;
};


export type Query_RootProductTypeModels_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypeModels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeModels_Order_By>>;
  where?: Maybe<ProductTypeModels_Bool_Exp>;
};


export type Query_RootProductTypeModels_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootProductTypePricesArgs = {
  distinct_on?: Maybe<Array<ProductTypePrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypePrices_Order_By>>;
  where?: Maybe<ProductTypePrices_Bool_Exp>;
};


export type Query_RootProductTypePrices_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypePrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypePrices_Order_By>>;
  where?: Maybe<ProductTypePrices_Bool_Exp>;
};


export type Query_RootProductTypesArgs = {
  distinct_on?: Maybe<Array<ProductTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypes_Order_By>>;
  where?: Maybe<ProductTypes_Bool_Exp>;
};


export type Query_RootProductTypes_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypes_Order_By>>;
  where?: Maybe<ProductTypes_Bool_Exp>;
};


export type Query_RootProductTypes_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Query_RootProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Query_RootProducts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSeasonsArgs = {
  distinct_on?: Maybe<Array<Seasons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Seasons_Order_By>>;
  where?: Maybe<Seasons_Bool_Exp>;
};


export type Query_RootSeasons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSnapshotAvailabilitiesArgs = {
  distinct_on?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotAvailabilities_Order_By>>;
  where?: Maybe<SnapshotAvailabilities_Bool_Exp>;
};


export type Query_RootSnapshotAvailabilities_AggregateArgs = {
  distinct_on?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotAvailabilities_Order_By>>;
  where?: Maybe<SnapshotAvailabilities_Bool_Exp>;
};


export type Query_RootSnapshotAvailabilities_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Seasons = {
   __typename?: 'seasons';
  created_at: Scalars['timestamptz'];
  endDate: Scalars['date'];
  id: Scalars['uuid'];
  location: Locations;
  locationId: Scalars['uuid'];
  name: Scalars['String'];
  startDate: Scalars['date'];
  updated_at: Scalars['timestamptz'];
};

export type Seasons_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Seasons_Bool_Exp>>>;
  _not?: Maybe<Seasons_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Seasons_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  endDate?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  startDate?: Maybe<Date_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

export type Seasons_Order_By = {
  created_at?: Maybe<Order_By>;
  endDate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  locationId?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

export type Seasons_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

export enum Seasons_Select_Column {
  CreatedAt = 'created_at',
  EndDate = 'endDate',
  Id = 'id',
  LocationId = 'locationId',
  Name = 'name',
  StartDate = 'startDate',
  UpdatedAt = 'updated_at'
}

export type SnapshotAvailabilities = {
   __typename?: 'snapshotAvailabilities';
  availableBikes: Scalars['Int'];
  date: Scalars['date'];
  id: Scalars['uuid'];
  product: Products;
  productId: Scalars['uuid'];
};

export type SnapshotAvailabilities_Aggregate = {
   __typename?: 'snapshotAvailabilities_aggregate';
  aggregate?: Maybe<SnapshotAvailabilities_Aggregate_Fields>;
  nodes: Array<SnapshotAvailabilities>;
};

export type SnapshotAvailabilities_Aggregate_Fields = {
   __typename?: 'snapshotAvailabilities_aggregate_fields';
  avg?: Maybe<SnapshotAvailabilities_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<SnapshotAvailabilities_Max_Fields>;
  min?: Maybe<SnapshotAvailabilities_Min_Fields>;
  stddev?: Maybe<SnapshotAvailabilities_Stddev_Fields>;
  stddev_pop?: Maybe<SnapshotAvailabilities_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SnapshotAvailabilities_Stddev_Samp_Fields>;
  sum?: Maybe<SnapshotAvailabilities_Sum_Fields>;
  var_pop?: Maybe<SnapshotAvailabilities_Var_Pop_Fields>;
  var_samp?: Maybe<SnapshotAvailabilities_Var_Samp_Fields>;
  variance?: Maybe<SnapshotAvailabilities_Variance_Fields>;
};


export type SnapshotAvailabilities_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type SnapshotAvailabilities_Aggregate_Order_By = {
  avg?: Maybe<SnapshotAvailabilities_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<SnapshotAvailabilities_Max_Order_By>;
  min?: Maybe<SnapshotAvailabilities_Min_Order_By>;
  stddev?: Maybe<SnapshotAvailabilities_Stddev_Order_By>;
  stddev_pop?: Maybe<SnapshotAvailabilities_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<SnapshotAvailabilities_Stddev_Samp_Order_By>;
  sum?: Maybe<SnapshotAvailabilities_Sum_Order_By>;
  var_pop?: Maybe<SnapshotAvailabilities_Var_Pop_Order_By>;
  var_samp?: Maybe<SnapshotAvailabilities_Var_Samp_Order_By>;
  variance?: Maybe<SnapshotAvailabilities_Variance_Order_By>;
};

export type SnapshotAvailabilities_Avg_Fields = {
   __typename?: 'snapshotAvailabilities_avg_fields';
  availableBikes?: Maybe<Scalars['Float']>;
};

export type SnapshotAvailabilities_Avg_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

export type SnapshotAvailabilities_Bool_Exp = {
  _and?: Maybe<Array<Maybe<SnapshotAvailabilities_Bool_Exp>>>;
  _not?: Maybe<SnapshotAvailabilities_Bool_Exp>;
  _or?: Maybe<Array<Maybe<SnapshotAvailabilities_Bool_Exp>>>;
  availableBikes?: Maybe<Int_Comparison_Exp>;
  date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  product?: Maybe<Products_Bool_Exp>;
  productId?: Maybe<Uuid_Comparison_Exp>;
};

export type SnapshotAvailabilities_Max_Fields = {
   __typename?: 'snapshotAvailabilities_max_fields';
  availableBikes?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
};

export type SnapshotAvailabilities_Max_Order_By = {
  availableBikes?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  productId?: Maybe<Order_By>;
};

export type SnapshotAvailabilities_Min_Fields = {
   __typename?: 'snapshotAvailabilities_min_fields';
  availableBikes?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
};

export type SnapshotAvailabilities_Min_Order_By = {
  availableBikes?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  productId?: Maybe<Order_By>;
};

export type SnapshotAvailabilities_Order_By = {
  availableBikes?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product?: Maybe<Products_Order_By>;
  productId?: Maybe<Order_By>;
};

export type SnapshotAvailabilities_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

export enum SnapshotAvailabilities_Select_Column {
  AvailableBikes = 'availableBikes',
  Date = 'date',
  Id = 'id',
  ProductId = 'productId'
}

export type SnapshotAvailabilities_Stddev_Fields = {
   __typename?: 'snapshotAvailabilities_stddev_fields';
  availableBikes?: Maybe<Scalars['Float']>;
};

export type SnapshotAvailabilities_Stddev_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

export type SnapshotAvailabilities_Stddev_Pop_Fields = {
   __typename?: 'snapshotAvailabilities_stddev_pop_fields';
  availableBikes?: Maybe<Scalars['Float']>;
};

export type SnapshotAvailabilities_Stddev_Pop_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

export type SnapshotAvailabilities_Stddev_Samp_Fields = {
   __typename?: 'snapshotAvailabilities_stddev_samp_fields';
  availableBikes?: Maybe<Scalars['Float']>;
};

export type SnapshotAvailabilities_Stddev_Samp_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

export type SnapshotAvailabilities_Sum_Fields = {
   __typename?: 'snapshotAvailabilities_sum_fields';
  availableBikes?: Maybe<Scalars['Int']>;
};

export type SnapshotAvailabilities_Sum_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

export type SnapshotAvailabilities_Var_Pop_Fields = {
   __typename?: 'snapshotAvailabilities_var_pop_fields';
  availableBikes?: Maybe<Scalars['Float']>;
};

export type SnapshotAvailabilities_Var_Pop_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

export type SnapshotAvailabilities_Var_Samp_Fields = {
   __typename?: 'snapshotAvailabilities_var_samp_fields';
  availableBikes?: Maybe<Scalars['Float']>;
};

export type SnapshotAvailabilities_Var_Samp_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

export type SnapshotAvailabilities_Variance_Fields = {
   __typename?: 'snapshotAvailabilities_variance_fields';
  availableBikes?: Maybe<Scalars['Float']>;
};

export type SnapshotAvailabilities_Variance_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

export type Subscription_Root = {
   __typename?: 'subscription_root';
  bookingRequest: Array<BookingRequest>;
  bookingRequest_by_pk?: Maybe<BookingRequest>;
  closingDates: Array<ClosingDates>;
  closingDates_by_pk?: Maybe<ClosingDates>;
  locations: Array<Locations>;
  locations_by_pk?: Maybe<Locations>;
  productPrices: Array<ProductPrices>;
  productPrices_aggregate: ProductPrices_Aggregate;
  productPrices_by_pk?: Maybe<ProductPrices>;
  productTypeAvailabilities: Array<ProductTypeAvailabilities>;
  productTypeAvailabilities_aggregate: ProductTypeAvailabilities_Aggregate;
  productTypeModels: Array<ProductTypeModels>;
  productTypeModels_aggregate: ProductTypeModels_Aggregate;
  productTypeModels_by_pk?: Maybe<ProductTypeModels>;
  productTypePrices: Array<ProductTypePrices>;
  productTypePrices_aggregate: ProductTypePrices_Aggregate;
  productTypes: Array<ProductTypes>;
  productTypes_aggregate: ProductTypes_Aggregate;
  productTypes_by_pk?: Maybe<ProductTypes>;
  products: Array<Products>;
  products_aggregate: Products_Aggregate;
  products_by_pk?: Maybe<Products>;
  seasons: Array<Seasons>;
  seasons_by_pk?: Maybe<Seasons>;
  snapshotAvailabilities: Array<SnapshotAvailabilities>;
  snapshotAvailabilities_aggregate: SnapshotAvailabilities_Aggregate;
  snapshotAvailabilities_by_pk?: Maybe<SnapshotAvailabilities>;
};


export type Subscription_RootBookingRequestArgs = {
  distinct_on?: Maybe<Array<BookingRequest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BookingRequest_Order_By>>;
  where?: Maybe<BookingRequest_Bool_Exp>;
};


export type Subscription_RootBookingRequest_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootClosingDatesArgs = {
  distinct_on?: Maybe<Array<ClosingDates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClosingDates_Order_By>>;
  where?: Maybe<ClosingDates_Bool_Exp>;
};


export type Subscription_RootClosingDates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLocationsArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Subscription_RootLocations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProductPricesArgs = {
  distinct_on?: Maybe<Array<ProductPrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductPrices_Order_By>>;
  where?: Maybe<ProductPrices_Bool_Exp>;
};


export type Subscription_RootProductPrices_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductPrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductPrices_Order_By>>;
  where?: Maybe<ProductPrices_Bool_Exp>;
};


export type Subscription_RootProductPrices_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProductTypeAvailabilitiesArgs = {
  distinct_on?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeAvailabilities_Order_By>>;
  where?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
};


export type Subscription_RootProductTypeAvailabilities_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeAvailabilities_Order_By>>;
  where?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
};


export type Subscription_RootProductTypeModelsArgs = {
  distinct_on?: Maybe<Array<ProductTypeModels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeModels_Order_By>>;
  where?: Maybe<ProductTypeModels_Bool_Exp>;
};


export type Subscription_RootProductTypeModels_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypeModels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeModels_Order_By>>;
  where?: Maybe<ProductTypeModels_Bool_Exp>;
};


export type Subscription_RootProductTypeModels_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootProductTypePricesArgs = {
  distinct_on?: Maybe<Array<ProductTypePrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypePrices_Order_By>>;
  where?: Maybe<ProductTypePrices_Bool_Exp>;
};


export type Subscription_RootProductTypePrices_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypePrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypePrices_Order_By>>;
  where?: Maybe<ProductTypePrices_Bool_Exp>;
};


export type Subscription_RootProductTypesArgs = {
  distinct_on?: Maybe<Array<ProductTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypes_Order_By>>;
  where?: Maybe<ProductTypes_Bool_Exp>;
};


export type Subscription_RootProductTypes_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypes_Order_By>>;
  where?: Maybe<ProductTypes_Bool_Exp>;
};


export type Subscription_RootProductTypes_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Subscription_RootProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Subscription_RootProducts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSeasonsArgs = {
  distinct_on?: Maybe<Array<Seasons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Seasons_Order_By>>;
  where?: Maybe<Seasons_Bool_Exp>;
};


export type Subscription_RootSeasons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSnapshotAvailabilitiesArgs = {
  distinct_on?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotAvailabilities_Order_By>>;
  where?: Maybe<SnapshotAvailabilities_Bool_Exp>;
};


export type Subscription_RootSnapshotAvailabilities_AggregateArgs = {
  distinct_on?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotAvailabilities_Order_By>>;
  where?: Maybe<SnapshotAvailabilities_Bool_Exp>;
};


export type Subscription_RootSnapshotAvailabilities_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};


export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type SendBookingRequestMutationVariables = {
  objects: Array<BookingRequest_Insert_Input>;
};


export type SendBookingRequestMutation = (
  { __typename?: 'mutation_root' }
  & { insert_bookingRequest?: Maybe<(
    { __typename?: 'bookingRequest_mutation_response' }
    & { returning: Array<(
      { __typename?: 'bookingRequest' }
      & Pick<BookingRequest, 'id'>
    )> }
  )> }
);

export type GetClosingDatesQueryVariables = {};


export type GetClosingDatesQuery = (
  { __typename?: 'query_root' }
  & { closingDates: Array<(
    { __typename?: 'closingDates' }
    & Pick<ClosingDates, 'closedDate' | 'description'>
  )> }
);

export type GetLocationsQueryVariables = {};


export type GetLocationsQuery = (
  { __typename?: 'query_root' }
  & { locations: Array<(
    { __typename?: 'locations' }
    & Pick<Locations, 'id' | 'name'>
  )> }
);

export type GetProductTypeQueryVariables = {
  startDate: Scalars['date'];
  endDate: Scalars['date'];
  duration: Scalars['numeric'];
  productTypeId: Scalars['uuid'];
  locationId: Scalars['uuid'];
};


export type GetProductTypeQuery = (
  { __typename?: 'query_root' }
  & { productTypes_by_pk?: Maybe<(
    { __typename?: 'productTypes' }
    & Pick<ProductTypes, 'id' | 'name' | 'description' | 'productTypeModelId' | 'image' | 'category'>
    & { products: Array<(
      { __typename?: 'products' }
      & Pick<Products, 'id' | 'size'>
      & { productPrices_aggregate: (
        { __typename?: 'productPrices_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'productPrices_aggregate_fields' }
          & { max?: Maybe<(
            { __typename?: 'productPrices_max_fields' }
            & Pick<ProductPrices_Max_Fields, 'basePricePerDay' | 'insuranceCostsPerDay' | 'protectionCostsPerDay'>
          )> }
        )> }
      ), snapshotAvailabilities_aggregate: (
        { __typename?: 'snapshotAvailabilities_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'snapshotAvailabilities_aggregate_fields' }
          & { min?: Maybe<(
            { __typename?: 'snapshotAvailabilities_min_fields' }
            & Pick<SnapshotAvailabilities_Min_Fields, 'availableBikes'>
          )> }
        )> }
      ) }
    )> }
  )> }
);

export type GetPoductTypesOverviewQueryVariables = {
  startDate: Scalars['date'];
  endDate: Scalars['date'];
  duration: Scalars['numeric'];
  locationId: Scalars['uuid'];
};


export type GetPoductTypesOverviewQuery = (
  { __typename?: 'query_root' }
  & { productTypes: Array<(
    { __typename?: 'productTypes' }
    & Pick<ProductTypes, 'id' | 'name' | 'category' | 'description' | 'image'>
    & { availabilities_aggregate: (
      { __typename?: 'productTypeAvailabilities_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'productTypeAvailabilities_aggregate_fields' }
        & { min?: Maybe<(
          { __typename?: 'productTypeAvailabilities_min_fields' }
          & Pick<ProductTypeAvailabilities_Min_Fields, 'sumAvailableBikes'>
        )> }
      )> }
    ), prices_aggregate: (
      { __typename?: 'productTypePrices_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'productTypePrices_aggregate_fields' }
        & { min?: Maybe<(
          { __typename?: 'productTypePrices_min_fields' }
          & Pick<ProductTypePrices_Min_Fields, 'minBasePricePerDay'>
        )>, max?: Maybe<(
          { __typename?: 'productTypePrices_max_fields' }
          & Pick<ProductTypePrices_Max_Fields, 'minBasePricePerDay'>
        )> }
      )> }
    ) }
  )> }
);

export type GetSeasonByDateQueryVariables = {
  date: Scalars['date'];
};


export type GetSeasonByDateQuery = (
  { __typename?: 'query_root' }
  & { seasons: Array<(
    { __typename?: 'seasons' }
    & Pick<Seasons, 'id' | 'name' | 'locationId' | 'startDate' | 'endDate' | 'created_at' | 'updated_at'>
  )> }
);

export type GetSeasonByLocationQueryVariables = {
  locationId: Scalars['uuid'];
};


export type GetSeasonByLocationQuery = (
  { __typename?: 'query_root' }
  & { seasons: Array<(
    { __typename?: 'seasons' }
    & Pick<Seasons, 'id' | 'name' | 'locationId' | 'startDate' | 'endDate' | 'created_at' | 'updated_at'>
  )>, closingDates: Array<(
    { __typename?: 'closingDates' }
    & Pick<ClosingDates, 'id' | 'locationId' | 'closedDate' | 'description'>
  )> }
);

export type BookingRequestSubscriptionVariables = {
  id: Scalars['uuid'];
};


export type BookingRequestSubscription = (
  { __typename?: 'subscription_root' }
  & { bookingRequest_by_pk?: Maybe<(
    { __typename?: 'bookingRequest' }
    & Pick<BookingRequest, 'success'>
  )> }
);


export const SendBookingRequestDocument = gql`
    mutation sendBookingRequest($objects: [bookingRequest_insert_input!]!) {
  insert_bookingRequest(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type SendBookingRequestMutationFn = ApolloReactCommon.MutationFunction<SendBookingRequestMutation, SendBookingRequestMutationVariables>;

/**
 * __useSendBookingRequestMutation__
 *
 * To run a mutation, you first call `useSendBookingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBookingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBookingRequestMutation, { data, loading, error }] = useSendBookingRequestMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useSendBookingRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendBookingRequestMutation, SendBookingRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<SendBookingRequestMutation, SendBookingRequestMutationVariables>(SendBookingRequestDocument, baseOptions);
      }
export type SendBookingRequestMutationHookResult = ReturnType<typeof useSendBookingRequestMutation>;
export type SendBookingRequestMutationResult = ApolloReactCommon.MutationResult<SendBookingRequestMutation>;
export type SendBookingRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<SendBookingRequestMutation, SendBookingRequestMutationVariables>;
export const GetClosingDatesDocument = gql`
    query getClosingDates {
  closingDates {
    closedDate
    description
  }
}
    `;

/**
 * __useGetClosingDatesQuery__
 *
 * To run a query within a React component, call `useGetClosingDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClosingDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClosingDatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClosingDatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClosingDatesQuery, GetClosingDatesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClosingDatesQuery, GetClosingDatesQueryVariables>(GetClosingDatesDocument, baseOptions);
      }
export function useGetClosingDatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClosingDatesQuery, GetClosingDatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClosingDatesQuery, GetClosingDatesQueryVariables>(GetClosingDatesDocument, baseOptions);
        }
export type GetClosingDatesQueryHookResult = ReturnType<typeof useGetClosingDatesQuery>;
export type GetClosingDatesLazyQueryHookResult = ReturnType<typeof useGetClosingDatesLazyQuery>;
export type GetClosingDatesQueryResult = ApolloReactCommon.QueryResult<GetClosingDatesQuery, GetClosingDatesQueryVariables>;
export const GetLocationsDocument = gql`
    query getLocations {
  locations {
    id
    name
  }
}
    `;

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, baseOptions);
      }
export function useGetLocationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, baseOptions);
        }
export type GetLocationsQueryHookResult = ReturnType<typeof useGetLocationsQuery>;
export type GetLocationsLazyQueryHookResult = ReturnType<typeof useGetLocationsLazyQuery>;
export type GetLocationsQueryResult = ApolloReactCommon.QueryResult<GetLocationsQuery, GetLocationsQueryVariables>;
export const GetProductTypeDocument = gql`
    query getProductType($startDate: date!, $endDate: date!, $duration: numeric!, $productTypeId: uuid!, $locationId: uuid!) {
  productTypes_by_pk(id: $productTypeId) {
    id
    name
    description
    productTypeModelId
    image
    category
    products(where: {_and: [{productPrices: {basePricePerDay: {_gte: 0}}}, {location: {id: {_eq: $locationId}}}]}) {
      id
      size
      productPrices_aggregate(where: {_and: {minDuration: {_lte: $duration}, basePricePerDay: {_gt: 0}}}, order_by: [{minDuration: desc}], limit: 1) {
        aggregate {
          max {
            basePricePerDay
            insuranceCostsPerDay
            protectionCostsPerDay
          }
        }
      }
      snapshotAvailabilities_aggregate(where: {date: {_gte: $startDate, _lte: $endDate}}) {
        aggregate {
          min {
            availableBikes
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetProductTypeQuery__
 *
 * To run a query within a React component, call `useGetProductTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductTypeQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      duration: // value for 'duration'
 *      productTypeId: // value for 'productTypeId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetProductTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductTypeQuery, GetProductTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductTypeQuery, GetProductTypeQueryVariables>(GetProductTypeDocument, baseOptions);
      }
export function useGetProductTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductTypeQuery, GetProductTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductTypeQuery, GetProductTypeQueryVariables>(GetProductTypeDocument, baseOptions);
        }
export type GetProductTypeQueryHookResult = ReturnType<typeof useGetProductTypeQuery>;
export type GetProductTypeLazyQueryHookResult = ReturnType<typeof useGetProductTypeLazyQuery>;
export type GetProductTypeQueryResult = ApolloReactCommon.QueryResult<GetProductTypeQuery, GetProductTypeQueryVariables>;
export const GetPoductTypesOverviewDocument = gql`
    query getPoductTypesOverview($startDate: date!, $endDate: date!, $duration: numeric!, $locationId: uuid!) {
  productTypes(order_by: {category: asc}) {
    id
    name
    category
    description
    image
    availabilities_aggregate(where: {_and: [{date: {_gte: $startDate, _lte: $endDate}}, {locationId: {_eq: $locationId}}]}) {
      aggregate {
        min {
          sumAvailableBikes
        }
      }
    }
    prices_aggregate(where: {minDuration: {_lte: $duration}}, order_by: [{minDuration: desc}]) {
      aggregate {
        min {
          minBasePricePerDay
        }
        max {
          minBasePricePerDay
        }
      }
    }
  }
}
    `;

/**
 * __useGetPoductTypesOverviewQuery__
 *
 * To run a query within a React component, call `useGetPoductTypesOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoductTypesOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoductTypesOverviewQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      duration: // value for 'duration'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetPoductTypesOverviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPoductTypesOverviewQuery, GetPoductTypesOverviewQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPoductTypesOverviewQuery, GetPoductTypesOverviewQueryVariables>(GetPoductTypesOverviewDocument, baseOptions);
      }
export function useGetPoductTypesOverviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPoductTypesOverviewQuery, GetPoductTypesOverviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPoductTypesOverviewQuery, GetPoductTypesOverviewQueryVariables>(GetPoductTypesOverviewDocument, baseOptions);
        }
export type GetPoductTypesOverviewQueryHookResult = ReturnType<typeof useGetPoductTypesOverviewQuery>;
export type GetPoductTypesOverviewLazyQueryHookResult = ReturnType<typeof useGetPoductTypesOverviewLazyQuery>;
export type GetPoductTypesOverviewQueryResult = ApolloReactCommon.QueryResult<GetPoductTypesOverviewQuery, GetPoductTypesOverviewQueryVariables>;
export const GetSeasonByDateDocument = gql`
    query GetSeasonByDate($date: date!) {
  seasons(where: {_and: [{startDate: {_lte: $date}}, {endDate: {_gte: $date}}]}, order_by: [{startDate: asc}], limit: 1) {
    id
    name
    locationId
    startDate
    endDate
    created_at
    updated_at
  }
}
    `;

/**
 * __useGetSeasonByDateQuery__
 *
 * To run a query within a React component, call `useGetSeasonByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeasonByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeasonByDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetSeasonByDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSeasonByDateQuery, GetSeasonByDateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSeasonByDateQuery, GetSeasonByDateQueryVariables>(GetSeasonByDateDocument, baseOptions);
      }
export function useGetSeasonByDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSeasonByDateQuery, GetSeasonByDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSeasonByDateQuery, GetSeasonByDateQueryVariables>(GetSeasonByDateDocument, baseOptions);
        }
export type GetSeasonByDateQueryHookResult = ReturnType<typeof useGetSeasonByDateQuery>;
export type GetSeasonByDateLazyQueryHookResult = ReturnType<typeof useGetSeasonByDateLazyQuery>;
export type GetSeasonByDateQueryResult = ApolloReactCommon.QueryResult<GetSeasonByDateQuery, GetSeasonByDateQueryVariables>;
export const GetSeasonByLocationDocument = gql`
    query GetSeasonByLocation($locationId: uuid!) {
  seasons(where: {locationId: {_eq: $locationId}}, order_by: [{startDate: asc}], limit: 1) {
    id
    name
    locationId
    startDate
    endDate
    created_at
    updated_at
  }
  closingDates(where: {locationId: {_eq: $locationId}}) {
    id
    locationId
    closedDate
    description
  }
}
    `;

/**
 * __useGetSeasonByLocationQuery__
 *
 * To run a query within a React component, call `useGetSeasonByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeasonByLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeasonByLocationQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetSeasonByLocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSeasonByLocationQuery, GetSeasonByLocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSeasonByLocationQuery, GetSeasonByLocationQueryVariables>(GetSeasonByLocationDocument, baseOptions);
      }
export function useGetSeasonByLocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSeasonByLocationQuery, GetSeasonByLocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSeasonByLocationQuery, GetSeasonByLocationQueryVariables>(GetSeasonByLocationDocument, baseOptions);
        }
export type GetSeasonByLocationQueryHookResult = ReturnType<typeof useGetSeasonByLocationQuery>;
export type GetSeasonByLocationLazyQueryHookResult = ReturnType<typeof useGetSeasonByLocationLazyQuery>;
export type GetSeasonByLocationQueryResult = ApolloReactCommon.QueryResult<GetSeasonByLocationQuery, GetSeasonByLocationQueryVariables>;
export const BookingRequestDocument = gql`
    subscription BookingRequest($id: uuid!) {
  bookingRequest_by_pk(id: $id) {
    success
  }
}
    `;

/**
 * __useBookingRequestSubscription__
 *
 * To run a query within a React component, call `useBookingRequestSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBookingRequestSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingRequestSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingRequestSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<BookingRequestSubscription, BookingRequestSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<BookingRequestSubscription, BookingRequestSubscriptionVariables>(BookingRequestDocument, baseOptions);
      }
export type BookingRequestSubscriptionHookResult = ReturnType<typeof useBookingRequestSubscription>;
export type BookingRequestSubscriptionResult = ApolloReactCommon.SubscriptionResult<BookingRequestSubscription>;

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": []
  }
};
      export default result;
    