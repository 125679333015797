import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    h1: {
      fontSize: '1.5rem',
      fontFamily: 'Raleway, sans-serif',
      paddingBottom: 0,
      marginBottom: '0.75em',
      //borderBottom: '1px solid #81d644',
      textAlign: 'center'
    },
    h2: {
      fontSize: '1.2rem',
      fontFamily: 'Raleway, sans-serif',
      paddingBottom: 0,
      marginBottom: '0.55em',
      //borderBottom: '1px solid #81d644',
      textAlign: 'center'
    },
    h5: {
      fontSize: '1rem',
      fontFamily: 'Raleway, sans-serif',
      marginTop: '1.5em',
      marginBottom: '1em',
    },
  },
  shape: {
    borderRadius: 4,
  },
  palette: {
    primary: {
      main: '#86c400', // '#81d644',
    },
    secondary: {
      main: '#353831',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
});

export default theme;
