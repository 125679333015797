import React, {useState} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  AppBar,
  Box,
  Button, Container,
  Divider,
  Grid,
  IconButton,
} from '@material-ui/core';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import {Link as RouterLink, useHistory} from 'react-router-dom';
import * as dateFns from "date-fns";

import Header from "../components/Header";
import Basket from '../components/Basket';
import {useGlobalStyles} from '../styles/global';

import {useSendBookingRequestMutation} from "../api/generated";
import {IAppStore} from '../store/appStore.interface';
import { parseISO } from 'date-fns';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperOverride: {
      paddingBottom: '10em',
    },
    divider: {
      marginTop: '0.5em',
      marginBottom: '0.5em',
    },
    addressEditButton: {
      padding: 0,
    },
    dividerTotal: {
      height: '5px'
    },
    collectionHint: {
      color: theme.palette.error.main,
      marginBottom: '2em',
      fontWeight: 'bold'
    },
    paperInfo: {
      paddingTop: '2em',
    },
    infoSubheader: {
      textAlign: 'center',
      fontWeight: 'normal'
    }
  }),
);

type SummaryProps = {
  store: IAppStore,
}

export default function Summary({store}: SummaryProps ) {
  let history = useHistory();

  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  const [showCollectionHintDetails, setShowCollectionHintDetails] = useState(false);

  const [
    sendBookingRequestMutation,
    // {
    //   loading: reservationRequestLoading,
    //   error: reservationRequestError
    // }
  ] = useSendBookingRequestMutation();

  // Handlers
  const handleReservationRequest = async () => {
    const request = store.getReservation();

    try {
      const bookingRequestResult = await sendBookingRequestMutation({
        variables: {
          objects: [
            {
              request: JSON.stringify(request)
            }
          ]
        }
      });

      const bookingRequestId = bookingRequestResult?.data?.insert_bookingRequest?.returning[0]?.id ?? ''

      if(bookingRequestId) {
        store.setBookingRequestId(bookingRequestId);
      }

      history.push('/confirmation');
    } catch(e) {
      console.log(`Error sending reservation: ${JSON.stringify(e)}`);
    }
  };

  const handleShowCollectionHintDetails = (show: boolean): void => {
    setShowCollectionHintDetails(show);
  };

  if (showCollectionHintDetails) {
    return (
      <>
        <Container component="main" maxWidth="md">
          <Box className={`${classes.paper} ${classes.paperInfo}`}>
            <Typography variant="h1">
              Abholung
            </Typography>

            <Typography variant="h5" className={classes.infoSubheader}>
              Bitte hole deine reservierten Artikel bis <i>spätestens 10:30</i> Uhr bei uns ab.<br /><br />

              <strong>Melde dich telefonisch bei uns, falls du dich verspäten solltest.</strong>
              <br /><br />
              <span className={classes.collectionHint}>Ansonsten behalten wir uns das Recht vor, den Artikel an wartende Kunden zu vergeben und deine Reservierung zu stornieren.</span>
            </Typography>
          </Box>
        </Container>

        <AppBar position={store.getFooterPosition()} color="default" className={classes.appBar}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => handleShowCollectionHintDetails(false)}
          >
            Zurück
          </Button>
        </AppBar>
      </>
    );
  }

  return (
    <>
      <Header store={store} />
      <Container component="main" maxWidth="md">
        <Box className={`${classes.paper} ${classes.paperOverride}`}>
          <Typography variant="h1">
            Übersicht
          </Typography>

          <Box className={classes.collectionHint}>
            <Grid container>
              <Grid xs={11}>
                <strong>Achtung</strong>: Reservierte Artikel müssen bis spätestens 10:30 abgeholt werden!
              </Grid>
              <Grid xs={1}>
                <IconButton
                  aria-label="Info"
                  color="default"
                  onClick={() => handleShowCollectionHintDetails(true)}
                >
                  <InfoIcon
                    color={"error"}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Box>

          <Basket store={store} />

          <Divider className={classes.dividerTotal} />

          <Typography variant="h5">
            Persönliche Daten
          </Typography>

          {store.getBookingRequest().type === 'customer' && (
            <Grid container>
              <Grid item xs={2}>
                <Button
                  variant="text"
                  color="default"
                  startIcon={<EditIcon />}
                  component={RouterLink}
                  to="/address"
                  className={classes.addressEditButton}
                />
              </Grid>
              <Grid item xs={10}>
                {store.getCustomer().firstname} {store.getCustomer().name} / {store.getCustomer().street} {store.getCustomer().streetnumber}<br />
                {store.getCustomer().countryCode} {store.getCustomer().zipCode} {store.getCustomer().city}<br />
                {store.getCustomer().email}<br />
                {store.getCustomer().phone}<br />
                {dateFns.format(parseISO(store.getCustomer().birthdate ?? ''), 'dd.MM.yyyy')}
              </Grid>
            </Grid>
          )}

          {store.getBookingRequest().type === 'admin' && (
            <Grid container>
              <Grid item xs={12}>
                <Typography variant={"subtitle1"} color={'error'}>Daten werden übernommen.</Typography>
              </Grid>
            </Grid>
          )}

        </Box>
      </Container>

      <AppBar position={store.getFooterPosition()} color="default" className={classes.appBar}>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          startIcon={<AddCircleIcon />}
          component={RouterLink}
          to="/products"
        >
          Weiteren Artikel reservieren
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={store.getBasket().items.length === 0}
          fullWidth
          onClick={handleReservationRequest}
        >
          Verbindlich reservieren
        </Button>
      </AppBar>
    </>
  );
};
