import React, { FC, useState } from 'react';
import { useGetSeasonByLocationQuery } from '../api/generated';
import DateFnsUtils from '@date-io/date-fns';
import { de } from 'date-fns/locale';
import {DatePicker as MuiDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import * as dateFns from 'date-fns';
import isWithinInterval from 'date-fns/isWithinInterval';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useGlobalStyles } from '../styles/global';

interface IDatePickerProps {
  locationId: string;
  onSelect: (date: Date) => Promise<void>;
  startDate?: Date;
  endDate?: Date;
}

const DatePicker: FC<IDatePickerProps> = ({ locationId, onSelect, startDate, endDate }) => {
  const classes = {
    ...useGlobalStyles(),
    ...useGlobalStyles(),
    ...useStyles(),
  };

  const [dateSelectedByUser, setDateSelectedByUser] = useState<boolean>((startDate && endDate) ? true : false);

  const {data: seasonData, loading: seasonLoading, error: seasonError} = useGetSeasonByLocationQuery({
    variables: { locationId }
  });

  if (seasonLoading || seasonError) {
    return <></>;
  }

  const season = seasonData?.seasons[0];
  const closingDates = seasonData?.closingDates ?? [];

  const minDate = season?.startDate;
  const maxDate = season?.endDate;

  const isDateValid = (day: MaterialUiPickersDate): boolean => {
    const dateString = dateFns.format(day ?? new Date('0000-00-00'), 'yyyy-MM-dd');
    const foundClosingDate = closingDates.find(date => date.closedDate === dateString);

    return !foundClosingDate;
  };

  const renderDay = (date: any, selectedDate: any, dayInCurrentMonth: boolean, dayComponent: JSX.Element) => {
    let isActive = false;

    if (startDate && endDate && date) {
      isActive = isWithinInterval(date, {
        start: dateFns.startOfDay(startDate),
        end: dateFns.endOfDay(endDate)
      }) && dateSelectedByUser;
    }

    return (
      <Box className={isActive ? classes.highlightedDay : ''}>
        {dayComponent}
      </Box>
    );
  };

  /** Helper methods */
  const isDateInvalid = (day: MaterialUiPickersDate) => {
    return !isDateValid(day);
  };

  const handleSelectDate = async (date: Date | null) => {
    setDateSelectedByUser(true);
    onSelect(date as Date);
  }

  return (
    <Box className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
        <MuiDatePicker
          autoOk
          minDate={minDate}
          maxDate={maxDate}
          shouldDisableDate={isDateInvalid}
          orientation="portrait"
          variant="static"
          openTo="date"
          defaultValue={null}
          value={startDate ?? null}
          onChange={(e) => handleSelectDate(e)}
          disablePast={true}
          disableToolbar={true}
          fullWidth
          renderDay={renderDay}
        />
      </MuiPickersUtilsProvider>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiPickersStaticWrapper-staticWrapperRoot': {
        minWidth: 'auto'
      },
      '& .MuiPickersBasePicker-pickerView': {
        minWidth: 'auto',
        maxWidth: 'auto',
      },
      '& .MuiPickersDay-daySelected': {
        backgroundColor: theme.palette.background.default,
      },
    },
    wrapperHighlightedDay: {},
    highlightedDay: {
      '& .MuiPickersDay-day': {
        backgroundColor: theme.palette.primary.main,
      },
      '& .MuiPickersDay-dayDisabled': {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.info.contrastText
      }
    }
  }),
);

export default DatePicker;