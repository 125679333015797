import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {IAppStore} from '../store/appStore.interface';
import { AppBar, Box } from "@material-ui/core";
import {useGlobalStyles} from '../styles/global';
import MiniBasket from './MiniBasket';
import { useHistory } from 'react-router-dom';

type HeaderProps = {
  store: IAppStore,
}

export default function Header({store}: HeaderProps) {
  const history = useHistory();

  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  const handleNavigateHome = () => {
    history.push('/');
  }

  return (
    <>
      <AppBar position="fixed" color="default" className={classes.appBar}>
        <Box
          className={classes.logoColumn}
          onClick={handleNavigateHome}
        >
          <img
            src={'/theme/img/logo.png'}
            className={classes.headerImage}
            alt={'Logo'}
          />
        </Box>

        {store.getLocation()?.id && (
          <MiniBasket store={store} />
        )}
      </AppBar>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      top: 0,
      backgroundColor: '#ffffff',
      height: '80px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    headerImage: {
      height: '45px',
      width: 'auto',
    },
    headerTitle: {
      '& h1': {
        textAlign: 'left',
        fontSize: '20px',
        color: '#ffffff',
        marginBottom: 0,
        paddingBottom: 0,
        marginTop: '16px',
        marginLeft: theme.spacing(2),
      }
    },
    logoColumn: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: '80px',
      textAlign: 'center',
      paddingLeft: '1em',
      paddingRight: '1em',
      minWidth: '25%',
    },
  }),
);
