import React, { useState } from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import { useGetLocationsQuery } from "../api/generated";
import { IAppStore, ILocation } from "../store/appStore.interface";
import {
  Box,
  Button,
  Container,
} from "@material-ui/core";
import {useHistory} from 'react-router-dom';
import Header from "../components/Header";
import {useGlobalStyles} from "../styles/global";
import Typography from "@material-ui/core/Typography";
import Footer from "../components/Footer";
import LocationSelector from '../components/LocationSelector';
// import LoadingContainer from '../components/LoadingContainer';

type LocationProps = {
  store: IAppStore,
}

export default function Location({store}: LocationProps) {
  let history = useHistory();
  const [location, setLocation] = useState<ILocation | undefined>(store.getLocation());

  const classes = {
    ...useGlobalStyles(),
    ...useGlobalStyles(),
    ...useStyles(),
  };

  /**
   * GraphQL
   */
  const {data: locationsData} = useGetLocationsQuery();

  const locations = locationsData?.locations ?? [];

  /**
   * Handlers
   */
  const handleConfirmLocation = (location: ILocation) => {
    setLocation(location);
  };

  const handleSubmitLocation = () => {
    if (location) {
      store.setLocation(location)
      history.push('/date-range');
    }
  }

  return (
    <>
      <Header store={store} />
      <Container component="main" maxWidth="md" className={classes.root}>
        <Box className={classes.paper}>
          <Box className={classes.content}>
            <Typography variant="h1">
              Neue Reservierung
            </Typography>
            <Typography variant="h2">
              Wo möchtest du starten?
            </Typography>
            <Typography variant="subtitle1">
              Wähle einen unserer Standorte, an dem du dein Bike reservieren und abholen möchtest.
            </Typography>

            <LocationSelector
              activeLocation={location}
              locations={locations}
              onSelect={handleConfirmLocation}
            />
          </Box>
        </Box>
      </Container>

      {
        location
        && (
          <Footer store={store}>
            <>
              <Button
                className={classes.primaryButton}
                variant="contained"
                color="primary"
                onClick={() => handleSubmitLocation()}
              >
                Standort ({location.name}) wählen
              </Button>
            </>
          </Footer>
        )
      }
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {

    },
    content: {
      textAlign: 'center',
    }
  }),
);