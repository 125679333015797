import React from 'react';

import { ApolloProvider } from "@apollo/react-common";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient, { DefaultOptions } from 'apollo-client';
import { split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import * as dateFns from 'date-fns';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import createPersistedState from 'use-persisted-state';
import { uuid } from 'uuidv4';

import ProductType from "./pages/ProductType";
import ProductTypes from "./pages/ProductTypes";
import Address from "./pages/Address";
import DateRange from "./pages/DateRange";
import {
  IAppStore,
  IBasket,
  IBasketItem,
  IBookingRequest,
  ICustomer,
  ILocation,
  IProduct,
  IReservation,
  IReservationDate
} from "./store/appStore.interface";
import Summary from "./pages/Summary";
import Confirmation from "./pages/Confirmation";

import { useGlobalStyles } from "./styles/global";
import Location from './pages/Location';
import BackButton from './components/BackButton';

/**
 * Apollo/GraphQL configs
 */
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_API_URL_WEBSOCKET ?? 'ws://localhost:8080/v1/graphql',
  options: {
    reconnect: true
  }
});

const cache = new InMemoryCache();
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL_HTTP ?? 'http://localhost:8080/v1/graphql',
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  cache: cache,
  link: link,
  defaultOptions: defaultOptions
});

/**
 * Query Params (used by backend and in kiosk mode)
 */
const locationSearch = new URLSearchParams(window.location.search);
const isKioskMode = locationSearch.get('kiosk') === '1';

// Clear local storage when kiosk mode was provided
//if (isKioskMode === true) {
localStorage.clear();
//}

// Admin mode configs
// @todo: Validate those backend params and handle invalid data
const existingOrderId = locationSearch.get('orderId');
const existingOrderStartDate = locationSearch.get('orderStartDate');
const existingOrderDuration = locationSearch.get('orderDuration');
const existingOrderLocationId = locationSearch.get('orderLocationId');

/**
 * Global states which sync with localStorage
 */
const useDateState = createPersistedState('date');
const useCurrentProductState = createPersistedState('currentProduct');
const useCustomerState = createPersistedState('customer');
const useLocationState = createPersistedState('location');
const useBasketState = createPersistedState('basket');
const useBookingRequestState = createPersistedState('bookingRequest');
const useAppSettingsState = createPersistedState('appSettings');

/**
 * Constants
 */
const INTERNAL_DATE_FORMAT = 'yyyy-MM-dd';

/**
 * Initial values
 */

let INITIAL_BOOKING_REQUEST = {
  id: '',
  success: false,
  type: 'customer'
};

/** Reservation */
let RESERVATION_DATE: IReservationDate = {
  // startDate: dateFns.format(new Date(), INTERNAL_DATE_FORMAT),
  duration: 0,
  configured: false,
};

// Overwrite defaults from existing Order
if (existingOrderId && existingOrderStartDate && existingOrderDuration) {
  INITIAL_BOOKING_REQUEST.type = 'admin';

  RESERVATION_DATE = {
    ...RESERVATION_DATE,
    startDate: existingOrderStartDate,
    duration: parseFloat(existingOrderDuration),
    configured: true,
  }
}

const CURRENT_PRODUCT: IProduct = {
  id: null
};

// const CURRENT_LOCATION: ILocation = {
//   id: null,
//   name: null,
// };

let CURRENT_LOCATION: ILocation | undefined = undefined;

if (existingOrderLocationId) {
  CURRENT_LOCATION = {
    id: existingOrderLocationId,
    name: null
  };
}

const CUSTOMER: ICustomer = {
  name: '',
  street: '',
  streetnumber: '',
  zipCode: '',
  city: '',
  countryCode: '',
  email: '',
  phone: '',
  birthdate: null,
};

const INITIAL_BASKET = {
  items: [] as IBasketItem[]
};

const INITIAL_APP_SETTINGS = {
  footerPosition: isKioskMode ? 'sticky' : 'fixed',
};

export default function App() {
  const classes = {
    ...useGlobalStyles(),
    ...useGlobalStyles(),
    ...useStyles(),
  };

  const [reservationDate, setReservationDate] = useDateState(RESERVATION_DATE);
  const [currentProduct, setCurrentProduct] = useCurrentProductState(CURRENT_PRODUCT);
  const [customer, setCustomer] = useCustomerState(CUSTOMER);
  const [location, setLocationData] = useLocationState(CURRENT_LOCATION);
  const [bookingRequest, setBookingRequest] = useBookingRequestState(INITIAL_BOOKING_REQUEST);
  const [appSettings/*, setAppSettings*/] = useAppSettingsState(INITIAL_APP_SETTINGS);

  const [basket, setBasket] = useBasketState(INITIAL_BASKET);

  const store: IAppStore = {
    getFooterPosition(): 'fixed' | 'absolute' | 'sticky' | 'static' | 'relative' {
      return appSettings.footerPosition as 'fixed' | 'absolute' | 'sticky' | 'static' | 'relative';
    },

    resetReservationDate(): void {
      setReservationDate(RESERVATION_DATE);
      setCurrentProduct(CURRENT_PRODUCT);
      setBasket(INITIAL_BASKET);
      setBookingRequest(INITIAL_BOOKING_REQUEST);
    },

    cleanupAfterSuccess: (): void => {
      if (isKioskMode) {
        setReservationDate(RESERVATION_DATE);
        setCurrentProduct(CURRENT_PRODUCT);
        setBasket(INITIAL_BASKET);
        setBookingRequest(INITIAL_BOOKING_REQUEST);
        setCustomer(CUSTOMER);
      }
    },

    confirmReservationDate(): void {
      setReservationDate(reservationDate => ({
        ...reservationDate,
        configured: true,
      }));
    },
    getReservation(): IReservation {
      if (undefined === store.getStartDate()) {
        throw new Error('Start date not set!');
      }

      return {
        basket: store.getBasket(),
        customer: store.getCustomer(),
        startDate: store.getStartDate() ?? '',
        duration: store.getDuration(),
        existingOrderId: existingOrderId,
      };
    },
    getStartDate: () => {
      if (!reservationDate.startDate) return undefined;

      return dateFns.format(dateFns.parseISO(reservationDate.startDate), INTERNAL_DATE_FORMAT);
    },
    setStartDate: (date: string) => {
      if (date === reservationDate.startDate) {
        return reservationDate;
      }

      return setReservationDate(reservationDate => ({
        ...reservationDate,
        startDate: date,
      }));
    },
    getDuration: () => reservationDate.duration,
    setDuration: (duration: number) => {
      setReservationDate(reservationDate => ({
        ...reservationDate,
        duration: duration,
      }));
    },
    getEndDate: () => {
      if (store.getDuration() === 0) return store.getStartDate() ?? '';

      const startDate = new Date(store.getStartDate() ?? new Date());
      const duration = Math.ceil(store.getDuration()) - 1;

      return dateFns.format(dateFns.add(startDate, { days: duration }), INTERNAL_DATE_FORMAT)
    },
    isReservationDateValid(): boolean {
      return reservationDate.configured ?? false;
    },

    setCurrentProduct: (product) => {
      // Set updated current product
      setCurrentProduct(product);
    },
    getCurrentProduct: (): IProduct => {
      return currentProduct;
    },
    isCurrentProductValid(): boolean {
      if (!this.getCurrentProduct().id) {
        return false;
      }

      if (!this.getCurrentProduct().driver?.configured) {
        return false;
      }

      return true;
    },

    getCustomer(): ICustomer {
      if (existingOrderId) {
        return { name: 'Wird aus bestehender Reservierung übernommen.' };
      }

      return customer;
    },

    setCustomer(customer: ICustomer): void {
      setCustomer(customer);
    },

    isCustomerValid(): boolean {
      if (existingOrderId) return true;

      const customerData = this.getCustomer();
      const invalidFields = Object.entries(customerData).filter(([key, val]) => {
        return !val;
      });

      // all form fields are obligatory
      if (invalidFields.length > 0) {
        return false;
      }

      return true;
    },

    getBasket(): IBasket {
      return {
        ...basket,
        total: basket.items.reduce((sum, item) => {
          const priceInfo = item.product.data?.price;

          let totalPrice = 0.00;

          // Product price
          totalPrice += (priceInfo?.basePricePerDay ?? 0.00);

          // Insurance
          totalPrice += item.product.crosssells?.insurance ? (priceInfo?.insuranceCostsPerDay ?? 0.00) : 0.00;

          // Protection
          totalPrice += item.product.crosssells?.protection ? (priceInfo?.protectionCostsPerDay ?? 0) : 0.00;

          return sum + (totalPrice * Math.ceil(this.getDuration()));
        }, 0.00)
      };
    },

    addProductToBasket(product: IProduct): IBasketItem {
      const newItem = {
        id: uuid(),
        product: product
      };

      setBasket({
        items: [
          ...this.getBasket().items,
          newItem
        ]
      });

      return newItem;
    },

    removeBasketItem(itemUuid: string): void {
      const currentBasket = this.getBasket();

      const newBasket = {
        ...currentBasket,
        items: currentBasket.items.filter(item => item.id !== itemUuid)
      };

      setBasket(newBasket);
    },

    setBookingRequestId(id: string): void {
      const newBookingRequest = {
        ...bookingRequest,
        id: id
      };
      setBookingRequest(newBookingRequest);
    },

    getBookingRequest(): IBookingRequest {
      return bookingRequest;
    },

    getLocation(): ILocation | undefined {
      return location;
    },

    setLocation(location: ILocation) {
      setLocationData(location);
    }
  };

  return (
    <ApolloProvider client={client}>
      <div className={classes.root}>
        <div className={classes.container}>
          {/**
           * MAIN CONTENT
           */}
          <Router>
            {/**
             * Basic router validation.
             *
             * If no dateRange is defined yet, always redirect to startPage.
             * Otherwise check which page should be rendered.
             */}
            <BackButton store={store}/>

            <Switch>
              <Route
                path="/"
                exact
                render={() => {
                  return (
                    <Location store={store} />
                  )
                }}
              />

              <Route
                path="/date-range"
                render={() => {
                  return (
                    store.getLocation() ? <DateRange store={store} /> : <Redirect to="/" />
                  )
                }}
              />

              <Route
                path="/product/:productTypeId"
                render={() => {
                  return (
                    store.getLocation() ? <ProductType store={store} /> : <Redirect to="/" />
                  )
                }}
              />

              <Route
                path="/address/:skip"
                render={() => {
                  return (
                    store.getLocation() ? <Address store={store} /> : <Redirect to="/" />
                  )
                }}
              />

              <Route
                path="/address"
                render={() => {
                  return (
                    store.getLocation() ? <Address store={store} /> : <Redirect to="/" />
                  )
                }}
              />

              <Route
                path="/summary"
                render={() => {
                  return (
                    store.getLocation() ? <Summary store={store} /> : <Redirect to="/" />
                  )
                }}
              />

              <Route
                path="/confirmation"
                render={() => {
                  return (
                    store.getLocation() ? <Confirmation store={store} /> : <Redirect to="/" />
                  )
                }}
              />

              <Route
                path="/products"
                render={() => {
                  return (
                    store.getLocation() ? <ProductTypes store={store} /> : <Redirect to="/" />
                  )
                }}
              />
            </Switch>
          </Router>
        </div>
      </div>
    </ApolloProvider>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // @todo: Make app smaller on wide screens
      // @todo: Note that header is using fixed/sticky layout which prevents some styling here
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    container: {
      width: '100%',
      maxWidth: '450px',
    }
  }),
);
