import React from 'react';
import { Box, Divider, Grid, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';

import { IAppStore } from '../store/appStore.interface';
import { useGlobalStyles } from '../styles/global';
import { formatPrice, priceTotalForDuration } from '../helpers/price';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    actionColumn: {
      textAlign: 'left',
      '& .MuiIconButton-root': {
        padding: 0,
      }
    },
    priceColumn: {
      textAlign: 'right'
    },
    totalRow: {
      fontWeight: 600,
      fontSize: '1.25em',
    },
    headerRow: {
      fontWeight: 600
    },
  }),
);

type BasketProps = {
  store: IAppStore,
}

export default function Basket({ store }: BasketProps) {
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  // Handlers
  const handleRemoveItem = (id: string) => {
    store.removeBasketItem(id);
  };

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid container className={classes.headerRow}>
          <Grid item xs={8}>Produkt</Grid>
          <Grid item xs={4} className={classes.priceColumn}>
            Preis ({store.getDuration()} Tag{store.getDuration() > 1 ? 'e' : ''})
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>

        {
          store.getBasket().items.map(item => (
            <>
              {/**
               * Product
               */}
              <Grid container key={item.id}>
                <Grid item xs={2} className={classes.actionColumn}>
                  <IconButton
                    aria-label="remove"
                    color="default"
                    onClick={() => handleRemoveItem(item.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={"subtitle2"}>
                    {item.product.data?.name} ({item.product.data?.size}) {item.product.driver?.name ? 'für ' + item.product.driver?.name : ''}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={classes.priceColumn}>
                  {formatPrice(priceTotalForDuration(item.product.data?.price?.basePricePerDay ?? 0, store.getDuration()))}
                </Grid>
              </Grid>

              {/**
               * Insurance
               */}
              {item.product.crosssells?.insurance && (
                <Grid container key={item.id + '-insurance'}>
                  <Grid item xs={2} className={classes.actionColumn}></Grid>
                  <Grid item xs={6}>
                    + Versicherung
                  </Grid>
                  <Grid item xs={4} className={classes.priceColumn}>
                    {formatPrice(priceTotalForDuration(item.product.data?.price?.insuranceCostsPerDay ?? 0, store.getDuration()))}
                  </Grid>
                </Grid>
              )}

              {/**
               * Protection
               */}
              {item.product.crosssells?.protection && (
                <Grid container key={item.id + '-protection'}>
                  <Grid item xs={2} className={classes.actionColumn}></Grid>
                  <Grid item xs={6}>
                    + Schutzausrüstung
                  </Grid>
                  <Grid item xs={4} className={classes.priceColumn}>
                    {formatPrice(priceTotalForDuration(item.product.data?.price?.protectionCostsPerDay ?? 0, store.getDuration()))}
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
            </>
          ))
        }

        {/**
         * Total
         */}
        <Divider />
        <Grid container item className={classes.totalRow}>
          <Grid item xs={8}>
            Gesamtpreis
          </Grid>
          <Grid item xs={4} className={classes.priceColumn}>
            {formatPrice(store.getBasket().total ?? 0)}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
