import React from 'react';

import { Fab } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { useGlobalStyles } from '../styles/global';
import { IAppStore } from '../store/appStore.interface';

type BackButtonProps = {
  store: IAppStore,
}

export default function BackButton({ store }: BackButtonProps) {
  const history = useHistory();

  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  const handleClick = () => {
    history.goBack();
  }

  if (history.location.pathname === '/' || (history.location.pathname === '/products' && store.getBasket().items.length > 0)) {
    return <></>;
  }

  return (
    <Fab
      aria-label="Zurück"
      size={'small'}
      className={classes.root}
      onClick={handleClick}
    >
      <ArrowBack />
    </Fab>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: 'calc(80px + 1em)',
      left: '1em',
      color: theme.palette.secondary.main,
    },
  })
);